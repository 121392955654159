import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Home() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  
  

  const testimonialsData = [
    {
      id: 1,
      image: "/assets/img/img-testimonials1.png",
      name: "John Doe",
      testimonial:
        "The courses offered through this LMS have been incredibly engaging and informative. I now feel more confident in my skills! - Green Valley High School",
    },
    {
      id: 2,
      image: "/assets/img/img-testimonials2.png",
      name: "Jane Smith",
      testimonial:
        "This platform helped me stay organized and complete my lessons on time. I love the interactive content! - Westbrook Academy",
    },
    {
      id: 3,
      image: "/assets/img/img-testimonials1.png",
      name: "Michael Johnson",
      testimonial:
        "I never thought online learning could be this fun. The quizzes and assignments kept me on my toes! - Horizon International School",
    },
    {
      id: 4,
      image: "/assets/img/img-testimonials2.png",
      name: "Emily Davis",
      testimonial:
        "The teachers and resources provided through this LMS are top-notch. I’ve learned so much in such a short time! - Maplewood High",
    },
    {
      id: 5,
      image: "/assets/img/img-testimonials1.png",
      name: "Sophia Lee",
      testimonial:
        "The user interface is intuitive, and the course materials are easy to follow. This LMS has changed the way I learn. - Silver Oak Academy",
    },
    {
      id: 6,
      image: "/assets/img/img-testimonials2.png",
      name: "David Brown",
      testimonial:
        "I appreciate the variety of subjects offered on this platform. The lessons are well-structured and easy to understand. - Lakeside Prep School",
    },
    {
      id: 7,
      image: "/assets/img/img-testimonials1.png",
      name: "Olivia Martinez",
      testimonial:
        "The LMS offers a seamless learning experience with plenty of support from instructors and peers. Highly recommend! - Crestwood Academy",
    },
    {
      id: 8,
      image: "/assets/img/img-testimonials2.png",
      name: "James Wilson",
      testimonial:
        "Thanks to this LMS, I can now study at my own pace and get the most out of every lesson. - Riverstone High School",
    },
  ];

  return (
    <div>
      {/* ---------------Hero Section----------- */}
      <div className="container-fluid p-5 bg-blue d-flex align-items-center">
        <div className="container">
          <div className="row w-100">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h2 className="hero-text">Education Empowers Education:</h2>
              <h1 className="hero-text">
                “ONE SOLUTION, <br />
                ENDLESS SUCCESS”
              </h1>
              <button className="btn btn-primary mt-3">GET STARTED</button>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center my-5">
              <img
                className="hero-img img-fluid"
                src="/assets/img/Group 185.png"
                alt="Image description"
              />
            </div>
          </div>
        </div>
      </div>

      {/* ---------Features Section----------------- */}
      <div className="container mt-5" id="features">
        <div className="align-items-center text-center">
          <h2 className="blue-text f-20 mb-0">FEATURES</h2>

          <img src="/assets/img/book.png" width={80} height={80} />
        </div>

        {/* Feature 1 */}
        <div className="row align-items-center mb-2">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <h3>Create Courses with Ease</h3>
            <p>
              Tutorio empowers educational institutions to effortlessly design,
              upload, and manage courses that align with their specific
              curriculum. Using our user-friendly course creation tools,
              teachers can develop courses quickly without needing advanced
              technical skills. Course customization options allow educators to
              adapt content to meet varied student needs, ensuring that teaching
              materials maintain high academic standards. Additionally, course
              templates streamline the process, enabling rapid replication of
              successful course structures. With accessible features for
              uploading multimedia, assessments, and interactive activities,
              educators have everything they need to enhance engagement and
              elevate learning.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            <img
              src="/assets/img/feature-img-1.png"
              alt="Empower Learning"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="row align-items-center mb-2 flex-row-reverse">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h3>Seamless School & Teacher Integration</h3>
            <p>
              The platform simplifies collaborative teaching by allowing schools
              to add multiple teachers and assign them to specific classes or
              subjects. Educators can co-manage course content, ensuring
              seamless transitions between teachers and consistent learning
              experiences for students. Administrators can assign roles and
              permissions, delegate tasks, and facilitate collaboration across
              departments, supporting diverse teaching models. With dedicated
              spaces for shared resources, communication, and updates, teachers
              can work together effectively, sharing feedback, adjusting course
              materials, and aligning instructional approaches to optimize
              student learning outcomes.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            <img
              src="/assets/img/feature-img-2.png"
              alt="Transform Education"
              className="img-fluid"
            />
          </div>
        </div>

        {/* Feature 3 */}
        <div className="row align-items-center mb-2">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h3>Student and Teacher Management</h3>
            <p>
              Managing students and teachers across courses has never been
              easier. Administrators can add students in bulk, sort them by
              learning level or group, and assign them to classes based on
              individual needs or progress. This feature ensures that students
              are placed in courses aligned with their abilities and goals,
              enhancing their engagement and success. Teachers also gain the
              flexibility to personalize course materials, set tailored
              assignments, and monitor individual student progress. By fostering
              interaction and adapting materials as needed, educators create an
              enriching environment where each student feels supported in their
              academic journey.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            <img
              src="/assets/img/feature-img-3.png"
              alt="Unlock the Future"
              className="img-fluid"
            />
          </div>
        </div>

        {/* Feature 4 (Reversed Order) */}
        <div className="row align-items-center mb-2 flex-row-reverse">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h3>Interactive Dashboard</h3>
            <p>
              Our advanced LMS dashboard offers a comprehensive overview of
              critical academic data. From assignment tracking and attendance
              monitoring to overall progress insights, this dashboard gives
              teachers and administrators an intuitive view of classroom
              dynamics and student engagement. Real-time analytics and reporting
              tools make it easy to identify trends, measure performance, and
              make informed decisions. Teachers can access quick summaries of
              student achievement, while administrators receive data-driven
              insights into institutional performance. The interactive dashboard
              keeps everyone informed and aligned, helping maintain an organized
              and effective educational environment.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            <img
              src="/assets/img/feature-img-4.png"
              alt="Redefine Education"
              className="img-fluid"
            />
          </div>
        </div>

        {/* Feature 5 */}
        <div className="row align-items-center mb-2">
          <div className="col-lg-6 col-md-6 col-sm-12 ">
            <h3>End-to-End Student Tracking</h3>
            <p>
              Tutorio supports the entire student lifecycle, from course
              enrollment to graduation, ensuring each step is thoroughly
              documented and easily trackable. Advanced student tracking tools
              provide detailed metrics on performance, attendance, assessment
              results, and participation. These insights reveal individual
              strengths and highlight areas for improvement, enabling teachers
              to deliver targeted support and guidance. With an end-to-end
              approach, teachers and institutions can celebrate student
              milestones and progress, fostering a supportive academic
              environment that values every learner’s journey.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            <img
              src="/assets/img/feature-img-5.png"
              alt="Course Creation"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* -------------------about us------------------- */}
      <div className="container-fluid min-vh-100 bg-blue-2 py-5 " id="about">
        <div className="container">
          {/* <div><img src="/assets/img/neonblurrywave.png"></img></div> */}
          <h2 className="white-text f-20 mb-0">ABOUT US</h2>
          <img
            className="bg-glow my-3"
            src="/assets/img/book2.png"
            width={100}
            height={75}
          />

          <div className="row">
            <div className="col-md-4">
              <div className="about-content">
                <img
                  className="img1  me-4"
                  src="/assets/img/https_/lottiefiles.com/animations/gear-ML0JUpqfxb.png"
                />
                <p>
                  At Tutorio, we are dedicated to transforming education by
                  providing a seamless platform that simplifies course creation,
                  management, and learning.
                </p>
              </div>

              <div className="about-content">
                <img
                  className="img2 me-3"
                  src="/assets/img/layers-TY2y7PZxVS.png"
                />
                <p>
                  Whether you're an institution looking to manage multiple
                  courses, a teacher aiming to engage your students more
                  effectively, or a student striving to learn at your own pace,
                  our all-in-one platform is designed to meet your needs.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="about-content">
                <img
                  className="img1 me-4"
                  src="/assets/img/Graduation Cap.png"
                />
                <p>
                  Our mission is to empower schools, teachers, and students with
                  the tools they need to succeed in today's digital world.
                </p>
              </div>
              <div className="about-content">
                <img
                  className="img4 me-3"
                  src="/assets/img/Project Management.png"
                />
                <p className="about-content">
                  We believe in creating a learning environment that fosters
                  collaboration, innovation, and continuous growth.
                </p>
              </div>
              <div className="about-content">
                <img className="img6 me-3" src="/assets/img/Launch.png" />
                <p className="about-content">
                  With cutting-edge technology, intuitive design, and a
                  commitment to user satisfaction, Tutorio is more than just a
                  platform — it's a comprehensive solution that enhances the
                  educational experience for everyone involved.
                </p>
              </div>
            </div>

            <div className="col-md-4 text-center icon-section">
              <img
                className="img-fluid"
                src="/assets/img/aboutus-img.png"
                alt="LMS Illustration"
              />
            </div>
          </div>
        </div>
      </div>
      {/* --------------------trusted companies------------------- */}
      <div className="bg-trust" hidden>
        <div className="text-center py-3">
          <h1 className="f-20">TRUSTED BY COMPANIES</h1>
          <h2 className="f-16">Chosen by Schools, Trusted by Companies</h2>
        </div>
        <div className="bg-trust-2">
          <div className="container-fluid ">
            <div className="row justify-content-evenly brand-bar align align-items-center">
              <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/Facebook_Changes_Company_Name_to_Meta__Welcome_To_The_Metaverse__Gulps_-removebg-preview 1.png"
                  alt="brands"
                />
              </div>
              <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/Google_Logo_PNG_-_Free_Download-removebg-preview 1.png"
                  alt="brands"
                />
              </div>
              <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/Graphic_Designer_Replaces_Wordmarks_In_Famous_Logos_With_The_Fonts_They_Use-removebg-preview 1.png"
                  alt="brands"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -----------testimonials ------------------ */}

      <div className="container-fluid bg-testimonials mt-2" hidden>
        {/* <img className="img-fluid bg-image" src="/assets/img/testimonials-bg-card.png" alt="Background" /> */}
        <img src="/assets/img/quote-sphere.png" className="quote-sphere" />
        <div className="container">
          <div className="bg-testimonials2 p-5">
            <div className="row align-items-center">
              <div className="col-md-3">
                <h6 className="text-white">Connect with other members</h6>
                <p className="text-white">
                  LMS is trusted by schools, teachers, and students to simplify
                  education and enhance learning. Hear from those who’ve
                  experienced the difference and see how our platform is
                  transforming education for the better.
                </p>
              </div>
              <div className="col-md-9 ">
                <Slider {...settings}>
                  {testimonialsData.map((item) => (
                    <div key={item.id} className="px-3 ">
                      <div className="card card-bordered testimonial-card rounded-0">
                        <div className="testimonial-image">
                          <img
                            src={item.image}
                            alt={item.name}
                            className="img-fluid testimonial-image"
                          />
                        </div>
                        <div className="testimonial-body">
                          <p className="testimonial-text">{item.testimonial}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* ------------------contact form-------------------- */}

      <div className="contact py-5" id="get quote">
        <div className="container">
          <div className="card card-shadow text-left rounded-4">
            <div className="row ">
              {/* Contact Info */}
              <div className="col-md-7 col-sm-12">
                <div className="contact-info p-4">
                  <h5 className="green">Contact Us</h5>
                  <h3 className="">Get in touch with us</h3>
                  <p className="mb-4">
                    Fill up the form and our team will get back to you within 24
                    hours
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="d-flex align-items-center mb-2">
                        <i className="fa-solid fa-location-dot me-2"></i>
                        <h3 className="mb-0">Visit us</h3>
                      </div>
                      <p>
                      104, Nasheman Firoza,
                        <br />
                        Apartments , Koh-e-fiza
                        <br /> Bhopal - 462030
                      </p>
                    </div>

                    <div className="col-lg-6">
                      <div className="d-flex align-items-center mb-2">
                        <i className="fa-solid fa-phone me-2"></i>
                        <h3 className="mb-0">Contact</h3>
                      </div>
                      <p>
                        Email: info@tutorio.in
                        <br />
                        Phone: +91-8770306617
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <img className="paperplane" src="/assets/img/Paper Plane.png"/> */}

              {/* Contact Form */}

              <div className="col-md-4 col-sm-12 ">
                <div className="contact-form bg-form text-white p-4 rounded-4 card-shadow">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="formName" className="form-label">
                        NAME
                      </label>
                      <input
                        type="text"
                        className="form-control field-style"
                        id="formName"
                        placeholder="John Doe"
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="formEmail" className="form-label">
                        EMAIL
                      </label>
                      <input
                        type="email"
                        className="form-control field-style"
                        id="formEmail"
                        placeholder="johndoe@gmail.com"
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="formContact" className="form-label">
                        CONTACT NUMBER
                      </label>
                      <input
                        type="text"
                        className="form-control field-style"
                        id="formContact"
                        placeholder="+91-8349113331"
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <button type="submit" className="btn btn-success ">
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-5 ms-auto">
                <img
                  className="img-fluid"
                  src="/assets/img/contact-shadow.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
