import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="bg-dark text-light ">
        <div className="footer-section pt-5">
          <div className="container text-md-left">
            <div className="row">
              {/* Footer Links Column 1 */}

              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <a href="#">
                  <img
                    src="/assets/img/tutorio-logo-footer.png"
                    alt="Logo"
                    className="logo p-2"
                    width="100"
                  />
                </a>

                <h6 className="text-uppercase fw-bold">Get to Know Us</h6>
                <a href="#" className="text-light mx-2"  style={{ fontSize: "1.5rem" }}>
                  <i className="fab fa-facebook"></i>
                </a>

                <a
                  href="https://www.instagram.com/_tutorio/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-light mx-2"
                  style={{ fontSize: "1.5rem" }} 
                >
                  <i className="fab fa-instagram"></i>
                </a>


                <br />
                <p>
                  Tutorio is an all-in-one learning platform, connecting
                  students with expert instructors across various fields. It’s
                  designed to streamline the learning experience for students
                  and educators alike.
                </p>
              </div>

              {/* Footer Links Column 2 */}
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Connect with Us</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#28a745",
                    height: "2px",
                  }}
                />
                <p>
                  <a
                    href="tel:+91888888888"
                    className="text-light d-block mb-2"
                  >
                    <i className="fa fa-phone me-2" aria-hidden="true"></i>
                    +91-8770306617
                  </a>
                  <a
                    href="mailto:team@lms.com"
                    className="text-light d-block mb-2"
                  >
                    <i class="fa-regular fa-envelope me-2"></i>
                    info@tutorio.in
                  </a>
                </p>
              </div>

              {/* Footer Links Column 3 */}
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Address</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#28a745",
                    height: "2px",
                  }}
                />
                <p className="text-light">
                  <h6 className="">
                    <i className="fa-solid fa-location-dot me-2"></i>
                    104, Nasheman Firoza,
                  </h6>
                  Apartments , Koh-e-fiza, Bhopal
                  <br />
                  Madhya Pradesh, 462030
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
